body[data-theme="dark"] {
  --body-bg: #2a2d3e;
  --text-color: #e0e0e0;
  --container-bg: #3b3f55;
}

body[data-theme="light"] {
  --body-bg: #ffffff;
  --text-color: #333333;
  --container-bg: #f0f0f0;
}

body {
  font-family: "Abel", Arial, sans-serif;
  background-color: var(--body-bg);
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  transition: all 0.4s ease;
}

header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.container {
  background-color: var(--container-bg);
  border-radius: 8px;
  padding: 30px 20px;
  width: 300px;
  text-align: center;
  position: relative;
  transition: all 0.4s ease;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
.container::after,
.container::before {
  --angle: 0deg;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: conic-gradient(
    from var(--angle),
    #57b846 25%,
    #00c1d4 50%,
    #e76e55 75%,
    #57b846 100%
  );
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: -1;
  padding: 2px;
  border-radius: 8px;
  animation: 3s spin linear infinite;
}

.container::before {
  filter: blur(1.5rem);
  opacity: 0.5;
}

@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}

.counter-text {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 25px;
  color: var(--text-color);
}

.counter {
  font-family: "Roboto Mono";
  font-weight: 200;
}

button {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  font-size: 16px;
  font-weight: bold;
  color: #f5f5f5;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.increment-btn {
  background-color: #57b846;
}

.increment-btn:hover {
  background-color: #4aa93d;
}

.decrement-btn {
  background-color: #e76e55;
}

.decrement-btn:hover {
  background-color: #d85f4b;
}
